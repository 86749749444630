<template>
  <div class="content md flex-box vertical">
    <div class="flex-grow scroll-area">
      <a-form-model
        ref="settingForm"
        :model="form"
        :rules="rules"
        label-align="left"
        :label-col="{span: 4}"
        :wrapper-col="{span: 20}"
      >
        <a-form-model-item label="到期提醒" prop="day_count">
          <span>到期前</span>
          <a-input-number :min="1" v-model="form.day_count" placeholder="请输入" style="margin: 0 8px"></a-input-number>
          <span>天进行提醒，每天提醒1次，共提醒{{form.day_count}}次</span>
        </a-form-model-item>
        <a-form-model-item label="提醒人" prop="users">
          <select-book multiple v-model="form.users" type="user"></select-book>
        </a-form-model-item>
        <a-form-model-item label="提醒方式" prop="methods">
          <a-checkbox-group v-model="form.methods" :options="methodOptions" />
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-space class="footer">
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" @click="handleSave">保存</a-button>
    </a-space>
  </div>
</template>

<script>

    export default {
        name: "ContractSetting",
        data() {
            return {
                form: {
                    day_count: 1
                },
                rules: {
                    day_count: [{ required: true, message: "请输入到期提醒天数", trigger: "change" }],
                    users: [{ required: true, message: "请选择提醒人", trigger: "change" }],
                    methods: [{ required: true, message: "请选择提醒方式", trigger: "change" }],
                },
                methodOptions: [
                    { label: "企业微信通知", value: 1 },
                    { label: "短信通知", value: 2 },
                    { label: "语音电话通知", value: 3 },
                ]
            }
        },
        created() {
            this.getSetting();
        },
        methods: {
            getSetting() {
                const url = `/admin/record-contract-config?pageSize=1`;
                this.$axios(url).then(res => {
                    if(res.data.length > 0) {
                        const item = res.data[0];
                        this.form = {
                            id: item.id,
                            day_count: item.day_count,
                            users: item.user_ids.split(","),
                            methods: item.remind_method.split(",").map(Number)
                        }
                    }
                });
            },
            handleCancel() {
                this.$router.go(-1);
            },
            handleSave() {
                this.$refs.settingForm.validate(flag => {
                    if (flag) {
                        const form = this.form;
                        const data = {
                            day_count: form.day_count,
                            user_ids: form.users.join(","),
                            remind_method: form.methods.join(",")
                        };
                        let url = "/admin/record-contract-config", method = "POST";
                        if(form.id) {
                            url += `/${form.id}`;
                            method = "PATCH";
                        }
                        this.$axios({
                            url,
                            method,
                            data
                        }).then(() => {
                            this.handleCancel();
                        });
                    }
                });
            }
        }
    }
</script>

<style lang="less">
.content .footer {
  padding-bottom: 0;
}
</style>
